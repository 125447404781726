import { h } from 'preact';
import { Popup as LPopup } from 'react-leaflet';
import React from 'react';

const Popup = ({ title, body, ...otherProps }) => {
    return (
        <LPopup
            className="map-popup"
            { ...otherProps }
        >
            <div class="map-popup__title">{ title }</div>
            <div
                class="map-popup__body"
                dangerouslySetInnerHTML={{ __html: body }}
            />
        </LPopup>
    );
};

export default Popup;
