import { h } from 'preact';
import { TileLayer as LTileLayer } from 'react-leaflet';
import React from 'react';

let mapBoxAccessToken = 'pk.eyJ1IjoicHJvcGhldHMiLCJhIjoiLWFoNzBHbyJ9.IabENq5fYTQ-CVcnith7cw';
let mapBoxStyleID = 'prophets/ckjod39vr0qrz19s3h53r7auu';

if (window.oascSite === 't4g') {
    mapBoxAccessToken = 'pk.eyJ1IjoicHJvcGhldHMiLCJhIjoiLWFoNzBHbyJ9.IabENq5fYTQ-CVcnith7cw';
    mapBoxStyleID = 'prophets/ckm6151gmd5yd17o4rb05o2fx';
}

if (window.oascSite === 'cxc') {
    mapBoxAccessToken = 'pk.eyJ1IjoicHJvcGhldHMiLCJhIjoiLWFoNzBHbyJ9.IabENq5fYTQ-CVcnith7cw';
    mapBoxStyleID = 'prophets/ckjod4iaz3p9j19pby0tw4zpb';
}

const Popup = ({ ...otherProps }) => {
    return (
        <LTileLayer
            url={ `https://api.mapbox.com/styles/v1/${ mapBoxStyleID }/tiles/{z}/{x}/{y}?access_token=${ mapBoxAccessToken }` }
            attribution='Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
            { ...otherProps }
        />
    );
};

export default Popup;
