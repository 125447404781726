import { h } from 'preact';
import { Map as LMap } from 'react-leaflet';
import Marker from './marker';
import Popup from './popup';
import TileLayer from './tile-layer';
import React from 'react';

const Map = ({ height: mapHeight, position: mapPosition, zoom: mapZoom, markers, ...otherProps }) => {
    return (
        <LMap
            center={ mapPosition }
            zoom={ mapZoom }
            class="map"
            style={{ height: mapHeight }}
            { ...otherProps }
        >
            <TileLayer />
            {
                markers.map(({ position, popup }) => (
                    <Marker position={ position }>
                        { popup &&
                            <Popup title={ popup.title } body={ popup.body } />
                        }
                    </Marker>
                ))
            }
        </LMap>
    );
};

export default Map;
