import { h, render } from 'preact';
import Map from './map';
import React from 'react';

const root = document.querySelector('#map-root');
if(root) {
    const defaultOptions = {
        markers: [],
        zoom: 4,
        height: '640px',
    };

    let options = defaultOptions;

    try {
        const dataSetOptions = JSON.parse(root.dataset.options.trim());
        options = Object.assign({}, defaultOptions, dataSetOptions);

        options.markers = options.markers.filter(({ position }) => position[0] && position[1]);
    } catch (e) {}

    const App = () => {
        return (
            <Map
                position={ [51.505, -0.09] }
                scrollWheelZoom={ false }
                { ...options }
            />
        );
    };

    // Inject our app into the DOM
    render(<App />, root);
}
