import { h } from 'preact';
import { Marker as LMarker } from 'react-leaflet';
import { Icon } from 'leaflet';
import React from 'react';

let iconUrl = '/images/map-marker-icc.svg';
if (window.oascSite === 't4g') {
    iconUrl = '/images/map-marker-t4g.svg';
}
if (window.oascSite === 'cxc') {
    iconUrl = '/images/map-marker-cxc.svg';
}

export const markerIcon = new Icon({
    iconUrl,
    iconRetinaUrl: iconUrl,
    iconAnchor: [8, 24],
    popupAnchor: [0, -24],
    iconSize: [16, 24],
});

const Marker = ({ position, children, ...otherProps }) => {
    return (
        <LMarker
            position={ position }
            icon={ markerIcon }
            { ...otherProps }
        >
            { children }
        </LMarker>
    );
};

export default Marker;
